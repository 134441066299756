import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Parametro } from "../model/app.parametro.model";

@Injectable()
export class RotasArquivo {

	readonly _baseUrlArquivo: string = `${environment.apiArquivoUrl}v1/`;

	get ObterAnexo(): string { return `${this._baseUrlArquivo}Minio/Download`}
	
	adicionarParametros(urlBase: string, parametros: Parametro[]) {
		let parametrosUrl: string = urlBase + '?';

		for (let i = 0; i < parametros.length; i++) {
			parametrosUrl += parametros[i].nome + '=' + parametros[i].data;
			if (i !== parametros.length - 1) {
				parametrosUrl += '&';
			}
		}
		return parametrosUrl;
	}

	parseRota(urlBase: string, valores: any[]) {
		let rota = urlBase;
		for (let i = 0; i < valores.length; i++) {
			const local = '{' + i + '}';
			rota = rota.replace(local, valores[i]);
		}
		return rota;
	}
}
