import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CardComponent } from './components/card/card.component';
import { GoogleAnalyticsGuard } from './guard/googleAnalytics.guard';
import { LinkExternoComponent } from './components/linkexterno/linkexterno.component';
import { LinkExternoGuard } from './guard/linkExterno.guard';
import { AuthTokenResolver } from './guard/authToken.resolver';

const routes: Routes = [
	{ path: '', component: CardComponent, resolve: [AuthTokenResolver] },
	{
		path: 'agrotoxico', data: { preload: true }, loadChildren: () => import('./components/consultas/agrotoxico/app.agrotoxico.module').then(x => x.AgrotoxicoModule),
		canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'eventos', loadChildren: () => import('./components/consultas/evento/app.evento.module').then(x => x.EventoModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'ficha', loadChildren: () => import('./components/consultas/ficha/app.ficha.module').then(x => x.FichaModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'laboratorio', loadChildren: () => import('./components/consultas/laboratorio/app.laboratorio.module').then(x => x.LaboratorioModule),
		canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'estabelecimento', loadChildren: () => import('./components/consultas/estabelecimento/app.estabelecimento.module').then(x => x.EstabelecimentoModule),
		canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'colaboradores', loadChildren: () => import('./components/consultas/colaborador/app.colaborador.module').then(x => x.ColaboradorModule),
		canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'gta', data: { preload: true }, loadChildren: () => import('./components/consultas/gta/app.gta.module').then(x => x.GtaModule),
		canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'vacinas', loadChildren: () => import('./components/consultas/vacina/app.vacina.module').then(x => x.VacinaModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'docoficiais', loadChildren: () => import('./components/consultas/relatoriodocoficiais/app.docoficiais.module').then(x => x.DocOficiaisModule),
		canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'animalIdentificado', loadChildren: () => import('./components/consultas/animal/app.animal.module').then(x => x.AnimalModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'ptv', loadChildren: () => import('./components/consultas/ptv/app.ptv.module').then(x => x.PtvModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'exame', loadChildren: () => import('./components/consultas/pnse/app.pnse.module').then(x => x.PnseModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'plantio', loadChildren: () => import('./components/cadastros/plantio/app.plantio.module').then(x => x.PlantioModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'nucleo', loadChildren: () => import('./components/consultas/nucleo/app.nucleo.module').then(x => x.NucleoModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'ecise', loadChildren: () => import('./components/consultas/ecise/app.ecise.module').then(x => x.ECISeModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'daems', loadChildren: () => import('./components/consultas/daems/app.daems.module').then(x => x.DaemsModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'cadastroFicha', loadChildren: () => import('./components/cadastros/fichasanitaria/app.fichasanitaria.module').then(x => x.FichaSanitariaModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'cadastroInstituicao', loadChildren: () => import('./components/cadastros/requerimento/app.requerimento.module').then(x => x.RequerimentoModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'requerimentoVegetal', loadChildren: () => import('./components/cadastros/requerimentovegetal/app.requerimentovegetal.module').then(x => x.RequerimentoVegetalModule), canActivate: [GoogleAnalyticsGuard]
	},
	{ path: 'cadastroTransporte', component: LinkExternoComponent, canActivate: [LinkExternoGuard] },
	{
		path: 'ferrugemSoja', loadChildren: () => import('./components/cadastros/ferrugemsoja/ferrugemsoja.module').then(x => x.FerrugemSojaModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'mapaFerrugemSoja', loadChildren: () => import('./components/consultas/mapaferrugem/mapaferrugem.module').then(x => x.MapaFerrugemModule), canActivate: [GoogleAnalyticsGuard]
	},
	{
		path: 'frutiferas', loadChildren: () => import('./components/cadastros/frutiferas/app.frutiferas.module').then(x => x.FrutiferasModule), canActivate: [GoogleAnalyticsGuard]
	},
	{path: 'comercioAgrotoxico', component: LinkExternoComponent, canActivate: [LinkExternoGuard] },
	{
		path: 'reconhecimentoDeAmostra', loadChildren: () => import('./components/cadastros/reconhecimentoAmostra/reconhecimentoAmostra.module').then(x => x.ReconhecimentoAmostraModule), canActivate: [GoogleAnalyticsGuard]
	},
	{ path: 'rebanhoms', component: LinkExternoComponent, canActivate: [LinkExternoGuard] },
	{ path: '**', component: CardComponent, resolve: [AuthTokenResolver] }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
	exports: [RouterModule],
	providers: [AuthTokenResolver]
})
export class AppRoutingModule { }
