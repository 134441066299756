import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LinkExternoGuard implements CanActivate {
  constructor() { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    const rota = route.url[0].path;
    const url = this.redirecionarRota(rota);
    window.open(url, '_blank');

    return false;
  }

  redirecionarRota(rota) {

    if (rota === 'cadastroTransporte') {
      return environment.production ? 'https://www.cadastramento.iagro.ms.gov.br/cadastrotransportadores' : 'https://hom.cadastramento.iagro.ms.gov.br/cadastrotransportadores';
    }

    if (rota === 'comercioAgrotoxico') {
      return 'https://paineis.msindicadores.ms.gov.br/publico/extensions/comerciodeagrotoxicos/comerciodeagrotoxicos.html';
	  }

	if (rota === 'rebanhoms') {
		return 'https://paineis.msindicadores.ms.gov.br/publico/extensions/monitor-rebanho-ms/monitor-rebanho-ms.html';
	  }

    return '';
  }



}
