import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Parametro } from "../model/app.parametro.model";

@Injectable()
export class RotasRequerimento {

	readonly _baseUrlRequerimento: string = `${environment.apiRequerimentoUrl}v1/`;

	get RequerimentoAnexo(): string { return `${this._baseUrlRequerimento}RequerimentoAnexo/TransmitirAnexoRequerimento` }
	get RequerimentoAnexoVegetal(): string { return `${this._baseUrlRequerimento}RequerimentoAnexo/TransmitirAnexoRequerimentoVegetal` }
	get ObterHistoricoRequerimento(): string { return `${this._baseUrlRequerimento}Requerimento/ObterHistoricoRequerimento` }
	get RemoverRequerimentoAnexo(): string { return `${this._baseUrlRequerimento}RequerimentoAnexo` }
	get ObterRequerimentoIndustriaPorInscricao(): string { return `${this._baseUrlRequerimento}Requerimento/ObterRequerimentoPorInscricao` }
	get InserirRequerimento(): string { return `${this._baseUrlRequerimento}Requerimento/CadastrarRequerimentoIndustria` };
	get AtualizarRequerimento(): string { return `${this._baseUrlRequerimento}Requerimento/AtualizarRequerimentoIndustria` };
	get ComunicarDesistencia(): string { return `${this._baseUrlRequerimento}Requerimento/ComunicarDesistencia?requerimentoID={0}` };
	get ComunicarDesistenciaVegetal(): string { return `${this._baseUrlRequerimento}RequerimentoVegetal/ComunicarDesistencia?requerimentoID={0}` };
	get ComunicarCoclusaoObra(): string { return `${this._baseUrlRequerimento}Requerimento/ComunicarConclusaoObra?requerimentoID={0}` };
	get ValidarSeFoiEnviadoAnexosObrigatorios(): string { return `${this._baseUrlRequerimento}RequerimentoAnexo/validarSeFoiEnviadoAnexosObrigatorios?requerimentoID={0}` };
	get AtualizarRequerimentoAnexo(): string { return `${this._baseUrlRequerimento}RequerimentoAnexo/AtualizarRequerimentoAnexo` };
	get AtualizarSituacaoRequerimentoComAnexo(): string { return `${this._baseUrlRequerimento}RequerimentoAnexo/AtualizarSituacaoRequerimentoComAnexo` };
	get AtualizarSituacaoRequerimento(): string { return `${this._baseUrlRequerimento}Requerimento/AtualizarSituacaoRequerimento` };
	get ObterAtividadesRequerimentoVegetal(): string { return `${this._baseUrlRequerimento}CategoriaEstabelecimentoVegetal/{0}/TipoAtividadesInstituicao` };
	get ObterContribuinteResumido(): string { return `${this._baseUrlRequerimento}Contribuinte/{0}/resumido` };
	get ObterEmailCodigoAcessoEstabelecimento(): string { return `${this._baseUrlRequerimento}CodigoAcesso/{0}/{1}/email` };
	get InserirRequerimentoVegetal(): string { return `${this._baseUrlRequerimento}RequerimentoVegetal/CadastrarRequerimentoVegetal` };
	get AtualizarSituacaoRequerimentoVegetalComAnexo(): string { return `${this._baseUrlRequerimento}RequerimentoVegetal/AtualizarSituacaoRequerimentoVegetalComAnexo` };
	get ObterRequerimentoVegetalPorIECPFCNPJ(): string { return `${this._baseUrlRequerimento}RequerimentoVegetal/ObterRequerimentoVegetalPorIECPFCNPJ` }
	get ObterHistoricoRequerimentoVegetal(): string { return `${this._baseUrlRequerimento}RequerimentoVegetal/ObterHistoricoRequerimentoVegetal` }
	get AtualizarRequerimentoVegetal(): string { return `${this._baseUrlRequerimento}RequerimentoVegetal/AtualizarRequerimentoVegetal` };

	adicionarParametros(urlBase: string, parametros: Parametro[]) {
		let parametrosUrl: string = urlBase + '?';

		for (let i = 0; i < parametros.length; i++) {
			parametrosUrl += parametros[i].nome + '=' + parametros[i].data;
			if (i !== parametros.length - 1) {
				parametrosUrl += '&';
			}
		}
		return parametrosUrl;
	}

	parseRota(urlBase: string, valores: any[]) {
		let rota = urlBase;
		for (let i = 0; i < valores.length; i++) {
			const local = '{' + i + '}';
			rota = rota.replace(local, valores[i]);
		}
		return rota;
	}
}
