import { RotasCadastro } from './helper/app.rotascadastro.utils';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './components/card/card.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SharedModule } from './components/shared/app.shared.module';
import { LinkExternoComponent } from './components/linkexterno/linkexterno.component';
import { Utils } from './helper/app.services.utils';
import { FichaService } from './services/app.ficha.services';
import { Rotas } from './helper/app.rotas.utils';
import { CoordenadaService } from './services/app.coordenada.services';
import { FilterPipe } from './filter.pipe';
import { FormsModule } from '@angular/forms';
import { BlockUtil } from './helper/app.block.utils';
import { RESTService } from './services/app.rest.services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UniversalDeviceDetectorService } from './helper/app.universal-device-detector.utils';
import { RequestService } from './services/app.request.services';
import { RequestAspNetAPIService } from './services/app.requestaspnetapiService.services';
import { RotasConsulta } from './helper/app.rotasconsulta.utils';
import { RotasArquivo } from './helper/app.rotasarquivo.utils';
import { RotasCultura } from './helper/app.rotascultura.utils';
import { RotasRequerimento } from './helper/app.rotasrequerimento.utils';

@NgModule({
	declarations: [
		AppComponent,
		CardComponent,
		FooterComponent,
		HeaderComponent,
		LinkExternoComponent,
		FilterPipe
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		AppRoutingModule,
		NgbModule,
		FormsModule,
		SharedModule.forRoot(),
		ToastrModule.forRoot()
	],
	providers: [
		Utils,
		FichaService,
		RESTService,
		RequestAspNetAPIService,
		RequestService,
		CoordenadaService,
		Rotas,
		RotasCadastro,
		RotasCultura,
		RotasConsulta,
		RotasArquivo,
		RotasRequerimento,
		BlockUtil,
		{
			provide: DeviceDetectorService,
			useClass: UniversalDeviceDetectorService
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
